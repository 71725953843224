import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';

function Racks() {
  const { user } = useAuth();

  const isAdmin = user?.roles?.includes('admin') || false;
  const isManager = user?.roles?.includes('manager') || false;
  const isEmployee = user?.roles?.includes('employee') || false;

  const [entityList, setEntityList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [associatedProducts, setAssociatedProducts] = useState({});
  
  const [addShelfModal, setAddShelfModal] = useState({ open: false, rackId: null, name: '', description: '', barcode_top_left: '', barcode_top_right: '', barcode_bottom_left: '', barcode_bottom_right: '' });
  const [editShelfModal, setEditShelfModal] = useState({ open: false, rackId: null, shelfId: null, name: '', description: '', barcode_top_left: '', barcode_top_right: '', barcode_bottom_left: '', barcode_bottom_right: '' });
  const [printModal, setPrintModal] = useState({ open: false, shelfName: '', barcode_top_left: '', barcode_top_right: '', barcode_bottom_left: '', barcode_bottom_right: '', barcode_top_left_value: '', barcode_top_right_value: '', barcode_bottom_left_value: '', barcode_bottom_right_value: '' });
  const [associateProductModal, setAssociateProductModal] = useState({
    open: false,
    shelfId: null,
    currentProducts: [],
    allProducts: [],
    selectedProducts: []
  });
  const [addRackModal, setAddRackModal] = useState({ open: false, storageAreaId: null, name: '', description: '' });
  const [editRackModal, setEditRackModal] = useState({ open: false, storageAreaId: null, rackId: null, name: '', description: '' });

  const fetchAllProducts = async () => {
    try {
      console.log("Fetching all products");
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/products', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      console.log("Received all products:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error;
    }
  };
  
  const fetchRacks = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await axios.get('https://rtinventory.cloud.backend.askgreg.fr/storage-areas-with-racks-and-shelves', {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      
      // Group data by entity
      const groupedData = response.data.reduce((acc, storageArea) => {
        if (!acc[storageArea.entity_id]) {
          acc[storageArea.entity_id] = {
            id: storageArea.entity_id,
            name: storageArea.entity_name,
            storageAreas: []
          };
        }
        acc[storageArea.entity_id].storageAreas.push(storageArea);
        return acc;
      }, {});

      setEntityList(Object.values(groupedData));
    } catch (error) {
      console.error("Error fetching racks:", error);
      setErrorMessage("Failed to fetch racks. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [user.token]);

  const fetchAssociatedProducts = useCallback(async (shelfId) => {
    try {
      const response = await axios.get(`https://rtinventory.cloud.backend.askgreg.fr/shelves/${shelfId}/products`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setAssociatedProducts(prev => ({ ...prev, [shelfId]: response.data }));
    } catch (error) {
      console.error("Error fetching associated products:", error);
    }
  }, [user.token]);

  useEffect(() => {
    fetchRacks();
  }, [fetchRacks]);

  useEffect(() => {
    entityList.forEach(entity => 
      entity.storageAreas.forEach(storageArea => 
        storageArea.racks.forEach(rack => 
          rack.shelves.forEach(shelf => 
            fetchAssociatedProducts(shelf.id)
          )
        )
      )
    );
  }, [entityList, fetchAssociatedProducts]);

  const handleAddRack = async () => {
    try {
      await axios.post(`https://rtinventory.cloud.backend.askgreg.fr/storage-areas/${addRackModal.storageAreaId}/add-rack`, {
        name: addRackModal.name,
        description: addRackModal.description,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setAddRackModal({ ...addRackModal, open: false });
      fetchRacks();
    } catch (error) {
      console.error("Error adding rack:", error);
      setErrorMessage("Failed to add rack. Please try again.");
    }
  };

  const handleEditRack = async () => {
    try {
      await axios.put(`https://rtinventory.cloud.backend.askgreg.fr/storage-areas/${editRackModal.storageAreaId}/racks/${editRackModal.rackId}`, {
        name: editRackModal.name,
        description: editRackModal.description,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setEditRackModal({ ...editRackModal, open: false });
      fetchRacks();
    } catch (error) {
      console.error("Error updating rack:", error);
      setErrorMessage("Failed to update rack. Please try again.");
    }
  };

  const handleDeleteRack = async (storageAreaId, rackId) => {
    try {
      await axios.delete(`https://rtinventory.cloud.backend.askgreg.fr/storage-areas/${storageAreaId}/remove-rack/${rackId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      fetchRacks();
    } catch (error) {
      console.error("Error deleting rack:", error);
      setErrorMessage("Failed to delete rack. Please try again.");
    }
  };

  const handleAddShelf = async () => {
    try {
      await axios.post(`https://rtinventory.cloud.backend.askgreg.fr/racks/${addShelfModal.rackId}/add-shelf`, {
        name: addShelfModal.name,
        description: addShelfModal.description,
        barcode_top_left: addShelfModal.barcode_top_left,
        barcode_top_right: addShelfModal.barcode_top_right,
        barcode_bottom_left: addShelfModal.barcode_bottom_left,
        barcode_bottom_right: addShelfModal.barcode_bottom_right,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setAddShelfModal({ ...addShelfModal, open: false });
      fetchRacks();
    } catch (error) {
      console.error("Error adding shelf:", error);
      setErrorMessage("Failed to add shelf. Please try again.");
    }
  };

  const handleEditShelf = async () => {
    try {
      await axios.put(`https://rtinventory.cloud.backend.askgreg.fr/racks/${editShelfModal.rackId}/shelves/${editShelfModal.shelfId}`, {
        name: editShelfModal.name,
        description: editShelfModal.description,
        barcode_top_left: editShelfModal.barcode_top_left,
        barcode_top_right: editShelfModal.barcode_top_right,
        barcode_bottom_left: editShelfModal.barcode_bottom_left,
        barcode_bottom_right: editShelfModal.barcode_bottom_right,
      }, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      setEditShelfModal({ ...editShelfModal, open: false });
      fetchRacks();
    } catch (error) {
      console.error("Error updating shelf:", error);
      setErrorMessage("Failed to update shelf. Please try again.");
    }
  };

  const handleDeleteShelf = async (rackId, shelfId) => {
    try {
      await axios.delete(`https://rtinventory.cloud.backend.askgreg.fr/racks/${rackId}/remove-shelf/${shelfId}`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      fetchRacks();
    } catch (error) {
      console.error("Error deleting shelf:", error);
      setErrorMessage("Failed to delete shelf. Please try again.");
    }
  };

  const handlePrintShelfBarcodes = async (rackId, shelfId) => {
    try {
      const response = await axios.get(`https://rtinventory.cloud.backend.askgreg.fr/racks/${rackId}/shelves/${shelfId}/arucos`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      const shelf = entityList
        .flatMap(entity => entity.storageAreas)
        .flatMap(storageArea => storageArea.racks)
        .flatMap(rack => rack.shelves)
        .find(s => s.id === shelfId);
      setPrintModal({
        open: true,
        shelfName: shelf ? shelf.name : 'Unknown Shelf',
        barcode_top_left: response.data.barcode_top_left.image,
        barcode_top_right: response.data.barcode_top_right.image,
        barcode_bottom_left: response.data.barcode_bottom_left.image,
        barcode_bottom_right: response.data.barcode_bottom_right.image,
        barcode_top_left_value: response.data.barcode_top_left.value,
        barcode_top_right_value: response.data.barcode_top_right.value,
        barcode_bottom_left_value: response.data.barcode_bottom_left.value,
        barcode_bottom_right_value: response.data.barcode_bottom_right.value,
      });
    } catch (error) {
      console.error("Error fetching QR codes:", error);
      setErrorMessage(`Failed to fetch QR codes for shelf ${shelfId} of rack ${rackId}.`);
    }
  };

  const handleAssociateProducts = async () => {
    if (!associateProductModal.shelfId) {
      console.error("Invalid shelf ID");
      return;
    }
    
    try {
      await axios.post(`https://rtinventory.cloud.backend.askgreg.fr/shelves/${associateProductModal.shelfId}/products`, 
        associateProductModal.selectedProducts,
        {
          headers: { Authorization: `Bearer ${user.token}` },
        }
      );
      
      setAssociateProductModal({ ...associateProductModal, open: false });
      fetchRacks();
    } catch (error) {
      console.error("Error updating product associations:", error);
      setErrorMessage("Failed to update product associations. Please try again.");
    }
  };

  const openAssociateProductModal = async (shelfId) => {
    console.log("Opening modal for shelf:", shelfId);
    try {
      const [allProducts, shelfProducts] = await Promise.all([
        fetchAllProducts(),
        fetchShelfProducts(shelfId)
      ]);
      
      console.log("All products:", allProducts);
      console.log("Shelf products:", shelfProducts);
      
      setAssociateProductModal({ 
        open: true, 
        shelfId: shelfId, 
        currentProducts: shelfProducts,
        allProducts: allProducts,
        selectedProducts: shelfProducts.map(p => p.id)
      });
    } catch (error) {
      console.error("Error opening associate product modal:", error);
      setErrorMessage("Failed to load products. Please try again.");
    }
  };

  const fetchShelfProducts = async (shelfId) => {
    try {
      console.log("Fetching products for shelf:", shelfId);
      const response = await axios.get(`https://rtinventory.cloud.backend.askgreg.fr/shelves/${shelfId}/products`, {
        headers: { Authorization: `Bearer ${user.token}` },
      });
      console.log("Received shelf products:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching shelf products:", error);
      return [];
    }
  };

  return (
    <div>
      <h1 className="title">Racks and Shelves Management</h1>
      <h2 className="subtitle">Manage the racks and shelves in storage areas of entities</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      {entityList.map((entity) => (
        <div key={entity.id} className="entity-section mb-6">
          <h3 className="title is-4">{entity.name}</h3>
          <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th className="has-text-centered is-size-7">Storage Area</th>
                <th className="has-text-centered is-size-7">Racks and Shelves</th>
              </tr>
            </thead>
            <tbody>
              {entity.storageAreas.map((storageArea) => (
                <tr key={storageArea.id}>
                  <td className="has-text-centered is-size-7">{storageArea.name}</td>
                  <td>
                    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                      <thead>
                        <tr>
                          <th className="has-text-centered is-size-7">Rack</th>
                          <th className="has-text-centered is-size-7">Shelves</th>
                        </tr>
                      </thead>
                      <tbody>
                        {storageArea.racks.map((rack) => (
                          <tr key={rack.id}>
                            <td className="has-text-centered is-size-7">
                              <span>{rack.name}</span>
                              <br />
                              <div className="buttons has-addons is-centered">
                                <p className="buttons">
                                  {isAdmin && (
                                    <button className="button is-small is-primary is-light" onClick={() => setEditRackModal({ open: true, storageAreaId: storageArea.id, rackId: rack.id, name: rack.name, description: rack.description })} title="Edit rack">
                                    <span className="icon is-small">
                                      <i className="fas fa-edit"></i>
                                    </span>
                                    <span>Edit</span>
                                  </button>
                                )}
                                {isAdmin && (
                                  <button className="button is-small is-danger is-light" onClick={() => handleDeleteRack(storageArea.id, rack.id)} title="Delete rack">
                                    <span className="icon is-small">
                                      <i className="fas fa-trash-alt"></i>
                                    </span>
                                    <span>Delete</span>
                                  </button>
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="is-size-7">
                            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                              <thead>
                                <tr>
                                  <th className="is-size-7">Shelf</th>
                                  <th className="has-text-centered is-size-7">QR Code bottom left</th>
                                  <th className="has-text-centered is-size-7">QR Code bottom right</th>
                                  <th className="has-text-centered is-size-7">QR Code top left</th>
                                  <th className="has-text-centered is-size-7">QR Code top right</th>
                                  <th className="is-size-7">Associated Products</th> 
                                  <th className="has-text-centered is-size-7">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {rack.shelves.map((shelf) => (
                                  <tr key={shelf.id}>
                                    <td className="is-size-7">{shelf.name}</td>
                                    <td className="has-text-centered is-size-7">{shelf.barcode_bottom_left}</td>
                                    <td className="has-text-centered is-size-7">{shelf.barcode_bottom_right}</td>
                                    <td className="has-text-centered is-size-7">{shelf.barcode_top_left}</td>
                                    <td className="has-text-centered is-size-7">{shelf.barcode_top_right}</td>
                                    <td className="is-size-7">
                                      {associatedProducts[shelf.id] && associatedProducts[shelf.id].map(product => (
                                        <div key={product.id}>{product.name}</div>
                                      ))}
                                    </td>
                                    <td className="actions">
                                      <div className="buttons has-addons is-centered">
                                        <p className="buttons">
                                          {(isAdmin || isManager) && (
                                            <button className="button is-small is-primary is-light" onClick={() => setEditShelfModal({ open: true, rackId: rack.id, shelfId: shelf.id, name: shelf.name, description: shelf.description, barcode_top_left: shelf.barcode_top_left, barcode_top_right: shelf.barcode_top_right, barcode_bottom_left: shelf.barcode_bottom_left, barcode_bottom_right: shelf.barcode_bottom_right })} title="Edit shelf">
                                              <span className="icon is-small">
                                                <i className="fas fa-edit"></i>
                                              </span>
                                              <span>Edit</span>
                                            </button>
                                          )}
                                          <button className="button is-small is-link is-light" onClick={() => handlePrintShelfBarcodes(rack.id, shelf.id)} title="Print QR codes">
                                            <span className="icon is-small">
                                              <i className="fas fa-print"></i>
                                            </span>
                                            <span>Print</span>
                                          </button>
                                          {(isAdmin || isManager) && (
                                            <button className="button is-small is-success is-light" onClick={() => openAssociateProductModal(shelf.id)} title="Associate products">
                                              <span className="icon is-small">
                                                <i className="fas fa-link"></i>
                                              </span>
                                              <span>Associate</span>
                                            </button>
                                          )}
                                          {isAdmin && (
                                            <button className="button is-small is-danger is-light" onClick={() => handleDeleteShelf(rack.id, shelf.id)} title="Delete shelf">
                                              <span className="icon is-small">
                                                <i className="fas fa-trash-alt"></i>
                                              </span>
                                              <span>Delete</span>
                                            </button>
                                          )}
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {isAdmin && (
                              <button className="button is-primary is-light is-small" onClick={() => setAddShelfModal({ open: true, rackId: rack.id, name: '', description: '', barcode_top_left: '', barcode_top_right: '', barcode_bottom_left: '', barcode_bottom_right: '' })}>
                                <span className="icon is-small">
                                  <i className="fas fa-plus" aria-hidden="true"></i>
                                </span>
                                <span>Add shelf</span>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {isAdmin && (
                    <button className="button is-primary is-light is-small" onClick={() => setAddRackModal({ open: true, storageAreaId: storageArea.id, name: '', description: '' })}>
                      <span className="icon is-small">
                        <i className="fas fa-plus" aria-hidden="true"></i>
                      </span>
                      <span>Add rack</span>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))}

    {/* Modal pour ajouter un rack */}
    <div className={`modal ${addRackModal.open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setAddRackModal({ ...addRackModal, open: false })}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add a rack</p>
          <button className="delete" aria-label="close" onClick={() => setAddRackModal({ ...addRackModal, open: false })}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input className="input" type="text" value={addRackModal.name} onChange={(e) => setAddRackModal({ ...addRackModal, name: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <textarea className="textarea" value={addRackModal.description} onChange={(e) => setAddRackModal({ ...addRackModal, description: e.target.value })}></textarea>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <p className="buttons">
            <button className="button is-primary is-small is-light" onClick={handleAddRack}>Submit</button>
            <button className="button is-danger is-small is-light" onClick={() => setAddRackModal({ ...addRackModal, open: false })}>Cancel</button>
          </p>
        </footer>
      </div>
    </div>

    {/* Modal pour éditer un rack */}
    <div className={`modal ${editRackModal.open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setEditRackModal({ ...editRackModal, open: false })}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Rack</p>
          <button className="delete" aria-label="close" onClick={() => setEditRackModal({ ...editRackModal, open: false })}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input className="input" type="text" value={editRackModal.name} onChange={(e) => setEditRackModal({ ...editRackModal, name: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <textarea className="textarea" value={editRackModal.description} onChange={(e) => setEditRackModal({ ...editRackModal, description: e.target.value })}></textarea>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <p className="buttons">
            <button className="button is-primary is-small is-light" onClick={handleEditRack}>Submit</button>
            <button className="button is-danger is-small is-light" onClick={() => setEditRackModal({ ...editRackModal, open: false })}>Cancel</button>
          </p>
        </footer>
      </div>
    </div>

    {/* Modal pour ajouter une étagère */}
    <div className={`modal ${addShelfModal.open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setAddShelfModal({ ...addShelfModal, open: false })}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add a shelf</p>
          <button className="delete" aria-label="close" onClick={() => setAddShelfModal({ ...addShelfModal, open: false })}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input className="input" type="text" value={addShelfModal.name} onChange={(e) => setAddShelfModal({ ...addShelfModal, name: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <textarea className="textarea" value={addShelfModal.description} onChange={(e) => setAddShelfModal({ ...addShelfModal, description: e.target.value })}></textarea>
            </div>
          </div>
          <div className="field">
            <label className="label">Bottom left QR</label>
            <div className="control">
              <input className="input" type="text" value={addShelfModal.barcode_bottom_left} onChange={(e) => setAddShelfModal({ ...addShelfModal, barcode_bottom_left: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Bottom right QR</label>
            <div className="control">
              <input className="input" type="text" value={addShelfModal.barcode_bottom_right} onChange={(e) => setAddShelfModal({ ...addShelfModal, barcode_bottom_right: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Top left QR</label>
            <div className="control">
              <input className="input" type="text" value={addShelfModal.barcode_top_left} onChange={(e) => setAddShelfModal({ ...addShelfModal, barcode_top_left: e.target.value })} required />
            </div>
          </div>
          <div className="field">
            <label className="label">Top right QR</label>
            <div className="control">
              <input className="input" type="text" value={addShelfModal.barcode_top_right} onChange={(e) => setAddShelfModal({ ...addShelfModal, barcode_top_right: e.target.value })} required />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <p className="buttons">
            <button className="button is-primary is-small is-light" onClick={handleAddShelf}>Submit</button>
            <button className="button is-danger is-small is-light" onClick={() => setAddShelfModal({ ...addShelfModal, open: false })}>Cancel</button>
          </p>
        </footer>
      </div>
    </div>

    {/* Modal pour éditer une étagère */}
    <div className={`modal ${editShelfModal.open ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setEditShelfModal({ ...editShelfModal, open: false })}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Shelf</p>
          <button className="delete" aria-label="close" onClick={() => setEditShelfModal({ ...editShelfModal, open: false })}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Name</label>
            <div className="control">
              <input 
                className="input" 
                type="text" 
                value={editShelfModal.name} 
                onChange={(e) => setEditShelfModal({ ...editShelfModal, name: e.target.value })} 
                required 
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <textarea 
                className="textarea" 
                value={editShelfModal.description} 
                onChange={(e) => setEditShelfModal({ ...editShelfModal, description: e.target.value })}
              ></textarea>
            </div>
          </div>
          <div className="field">
            <label className="label">Bottom left QR</label>
            <div className="control">
              <input 
                className="input" 
                type="text" 
                value={editShelfModal.barcode_bottom_left} 
                onChange={(e) => setEditShelfModal({ ...editShelfModal, barcode_bottom_left: e.target.value })} 
                required 
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Bottom right QR</label>
            <div className="control">
              <input 
                className="input" 
                type="text" 
                value={editShelfModal.barcode_bottom_right} 
                onChange={(e) => setEditShelfModal({ ...editShelfModal, barcode_bottom_right: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Top left QR</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={editShelfModal.barcode_top_left} 
                  onChange={(e) => setEditShelfModal({ ...editShelfModal, barcode_top_left: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Top right QR</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={editShelfModal.barcode_top_right} 
                  onChange={(e) => setEditShelfModal({ ...editShelfModal, barcode_top_right: e.target.value })} 
                  required 
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <p className="buttons">
              <button className="button is-primary is-small is-light" onClick={handleEditShelf}>Submit</button>
              <button className="button is-danger is-small is-light" onClick={() => setEditShelfModal({ ...editShelfModal, open: false })}>Cancel</button>
            </p>
          </footer>
        </div>
      </div>

      {/* Modal pour imprimer les codes QR */}
      <div className={`modal ${printModal.open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setPrintModal({ ...printModal, open: false })}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Print barcode for shelf: {printModal.shelfName}</p>
            <button className="delete" aria-label="close" onClick={() => setPrintModal({ ...printModal, open: false })}></button>
          </header>
          <section className="modal-card-body">
            <div className="grid">
              <div className="cell">
                <img src={`data:image/png;base64,${printModal.barcode_top_left}`} alt="QR Code top left" />
                <p>top left: {printModal.barcode_top_left_value}</p>
              </div>
              <div className="cell">
                <img src={`data:image/png;base64,${printModal.barcode_top_right}`} alt="QR Code top right" />
                <p>top right: {printModal.barcode_top_right_value}</p>
              </div>
              <div className="cell">
                <img src={`data:image/png;base64,${printModal.barcode_bottom_left}`} alt="QR Code bottom left" />
                <p>bottom left: {printModal.barcode_bottom_left_value}</p>
              </div>
              <div className="cell">
                <img src={`data:image/png;base64,${printModal.barcode_bottom_right}`} alt="QR Code bottom right" />
                <p>bottom right: {printModal.barcode_bottom_right_value}</p>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <p className="buttons">
              <button className="button is-primary is-small is-light" onClick={() => window.print()}>Print</button>
              <button className="button is-danger is-small is-light" onClick={() => setPrintModal({ ...printModal, open: false })}>Close</button>
            </p>
          </footer>
        </div>
      </div>

      {/* Modal pour associer des produits */}
      <div className={`modal ${associateProductModal.open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setAssociateProductModal({ ...associateProductModal, open: false })}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Associate Products to Shelf</p>
            <button className="delete" aria-label="close" onClick={() => setAssociateProductModal({ ...associateProductModal, open: false })}></button>
          </header>
          <section className="modal-card-body">
            {console.log("Rendering modal with products:", associateProductModal.allProducts)}
            <div className="field">
              {associateProductModal.allProducts && associateProductModal.allProducts.length > 0 ? (
                <div className="checkboxes">
                  {associateProductModal.allProducts.map((product) => (
                    <label key={product.id} className="checkbox">
                      <input 
                        type="checkbox" 
                        checked={associateProductModal.selectedProducts.includes(product.id)} 
                        onChange={() => {
                          const updatedSelectedProducts = associateProductModal.selectedProducts.includes(product.id)
                            ? associateProductModal.selectedProducts.filter(id => id !== product.id)
                            : [...associateProductModal.selectedProducts, product.id];
                          setAssociateProductModal(prevState => ({
                            ...prevState,
                            selectedProducts: updatedSelectedProducts
                          }));
                        }} 
                      />
                      {' '}{product.name}
                    </label>
                  ))}
                </div>
              ) : (
                <p>No products available</p>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            <p className="buttons">
              <button className="button is-primary is-small is-light" onClick={handleAssociateProducts}>Submit</button>
              <button className="button is-danger is-small is-light" onClick={() => setAssociateProductModal({ ...associateProductModal, open: false })}>Cancel</button>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Racks;